import { Link } from '@chakra-ui/next-js';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { RedirectToAppButton } from '@/widgets/RedirectToAppButton';
import { navLinks } from '@/shared/constants';
import { usePageName } from '@/shared/hooks';
import { BurgerIcon, CloseIcon, SelectAccordion } from '@/shared/ui';
import { localStorage } from '@/shared/utils';

const MobileMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const pageName = usePageName();

  const isShowProducts = localStorage.getItem('isShowProducts') !== 'false';
  let currentLinks = navLinks;

  if (!isShowProducts) {
    currentLinks = navLinks.filter((item) => item.title !== 'Products');
  }

  return (
    <Box display={{ base: 'block', lg: 'none' }}>
      <IconButton
        aria-label='Open menu button'
        aria-labelledby='Open menu button'
        gridArea='menu'
        icon={<BurgerIcon boxSize={6} />}
        variant='iconButton'
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        size='xs'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent overflowY='auto'>
          <DrawerHeader
            as='div'
            display='flex'
            justifyContent='end'
            px='md'
          >
            <IconButton
              aria-label='Close menu button'
              aria-labelledby='Close menu button'
              icon={<CloseIcon boxSize={6} />}
              variant='iconButton'
              onClick={onClose}
            />
          </DrawerHeader>
          <DrawerBody
            as='nav'
            display='flex'
            flexDirection='column'
            gap='4'
            pt='lg'
          >
            {currentLinks.map(({ title, items, href }) => {
              if (items) {
                return (
                  <SelectAccordion
                    key={title}
                    list={items}
                    title={title}
                    onClickLink={onClose}
                  />
                );
              }
              return (
                <Link
                  key={title}
                  href={href!}
                  onClick={onClose}
                >
                  {title}
                </Link>
              );
            })}
            <RedirectToAppButton
              buttonText='Login'
              dataLayer={{ data: { redirected_from: `${pageName}_mobile_menu` } }}
              display={{ base: 'flex', md: 'none' }}
              mt='lg'
              pageName='login'
              variant='link'
              w='fit-content'
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
